/**
 * @fileOverview アイコンライブラリで定義されない、Figma から SVG export したオリジナルアイコンのコンポーネント
 */
import ArrowLeftStartOnLine from "./arrow-left-start-on-line.svg"
import ArrowPathClock from "./arrow-path-clock.svg"
import ArrowRightEndOnLine from "./arrow-right-end-on-line.svg"
import ArrowsPointingOut2 from "./arrows-pointing-out-2.svg"
import DoneAll from "./done-all.svg"
import Facebook from "./facebook.svg"
import Hubspot from "./hubspot.svg"
import LinkSlash from "./link-slash.svg"
import LinkedIn from "./linked-in.svg"
import LoaderCircle from "./loader-circle.svg"
import Modal from "./modal.svg"
import Reload from "./reload.svg"
import RemoveDone from "./remove-done.svg"
import RemoveList from "./remove-list.svg"
import Salesforce from "./salesforce.svg"
import SortAsc from "./sort-asc.svg"
import SortDesc from "./sort-desc.svg"
import SortOff from "./sort-off.svg"
import SparkleGlassMicro from "./sparkle-glass/micro.svg"
import SparkleGlassSolid from "./sparkle-glass/solid.svg"
import TriangleRightMicro from "./triangle-right/micro.svg"
import TriangleRightSolid from "./triangle-right/solid.svg"
import X from "./x.svg"

export const iconNameList = [
  "arrow-path-clock",
  "done-all",
  "arrow-left-start-on-line",
  "arrow-right-end-on-line",
  "arrows-pointing-out-2",
  "facebook",
  "hubspot",
  "reload",
  "remove-done",
  "remove-list",
  "linked-in",
  "link-slash",
  "loader-circle",
  "modal",
  "salesforce",
  "sort-asc",
  "sort-desc",
  "sort-off",
  "sparkle-glass",
  "triangle-right",
  "x",
] as const

export type Props = React.SVGAttributes<SVGElement> & {
  name: (typeof iconNameList)[number]
  variant?: "micro" | "mini" | "outline" | "solid"
}

const ICON_PROPS = {
  // FIXME: Storybook で @svgr/webpack の最適化によりプロパティ viewBox が消えてしまう問題の対応として、Props として渡す。
  viewBox: "0 0 24 24",
}

export const Icon = ({ name, variant, ...rest }: Props) => {
  switch (name) {
    case "arrow-path-clock":
      return <ArrowPathClock viewBox="0 0 12 12" {...rest} />
    case "done-all":
      return <DoneAll {...rest} />
    case "arrow-left-start-on-line":
      return <ArrowLeftStartOnLine viewBox="0 0 16 16" {...rest} />
    case "arrow-right-end-on-line":
      return <ArrowRightEndOnLine viewBox="0 0 16 16" {...rest} />
    case "arrows-pointing-out-2":
      return <ArrowsPointingOut2 viewBox="0 0 16 16" {...rest} />
    case "facebook":
      return <Facebook {...ICON_PROPS} {...rest} />
    case "hubspot":
      return <Hubspot {...rest} />
    case "remove-done":
      return <RemoveDone {...rest} />
    case "remove-list":
      return <RemoveList {...rest} />
    case "reload":
      return <Reload viewBox="0 0 16 16" {...rest} />
    case "linked-in":
      return <LinkedIn {...ICON_PROPS} {...rest} />
    case "link-slash":
      return <LinkSlash {...ICON_PROPS} {...rest} />
    case "loader-circle":
      return <LoaderCircle {...ICON_PROPS} {...rest} />
    case "modal":
      return <Modal {...ICON_PROPS} {...rest} />
    case "salesforce":
      return <Salesforce {...rest} />
    case "sparkle-glass":
      if (variant === "micro") return <SparkleGlassMicro {...rest} />
      if (variant === "solid") return <SparkleGlassSolid {...rest} />
    case "x":
      return <X {...ICON_PROPS} {...rest} />
    case "sort-asc":
      return <SortAsc {...ICON_PROPS} {...rest} />
    case "sort-desc":
      return <SortDesc {...ICON_PROPS} {...rest} />
    case "sort-off":
      return <SortOff {...ICON_PROPS} {...rest} />
    case "triangle-right":
      if (variant === "micro") return <TriangleRightMicro {...rest} />
      if (variant === "solid") return <TriangleRightSolid {...rest} />
    default:
      return null
  }
}
